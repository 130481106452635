import React, { useState } from 'react'

import ImageWebp from 'src/components/ImageWebp'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import OpenVideo from 'src/components/OpenVideo'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ScrollTo from 'src/components/ScrollTo'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import playVideo from 'src/assets/images/shared/play-video-gray.png'
import Inter from './assets/assets/images/inter.png'

import videosJSON from './assets/assets/investindo-na-pratica-data.json'
import supportingContentJSON from './assets/assets/supporting-content.json'

import { VideoSection, DotsDiv, ThumbContainer, PlayerVideoContainer, Number, Content } from './style'

type SupportingContentProps = {
  title: string;
  url: string;
}

type DotsDivsProps = {
  link: string;
  thumbnail: string;
  canShow: boolean;
  titleVideo: string;
  title: string;
  list: {
    topics: string;
  }[];
}

const NossosVideos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const width = useWidth(300)
  const videos = videosJSON
  const [ carouselIndex, setCarouselIndex ] = useState(0)

  return (
    <VideoSection className='bg-gray pt-4 pb-5' id='webserie'>
      <div className='container'>
        <div className='row'>
          <img className='mb-5 mb-md-4 logo-inter' alt='Logo Inter' src={Inter} />
        </div>
        <div className='row mt-md-4'>
          <h1 className='fs-24 fs-md-32 lh-30 lh-md-40 text-grayscale--500 mb-lg-4'>
            <span className='d-lg-block d-xl-inline'><span className='fw-600'>Olá,</span> Bem-vindo(a) à websérie</span> <span className='fw-600'>Finanças na Prática</span>
          </h1>
        </div>
        <div className='row'>
          <PlayerVideoContainer className='col-12 col-lg-8 col-xl-9 pl-md-0 px-0'>
            <div className='video-container mt-4 mt-md-3 mt-lg-0 d-flex justify-content-center justify-content-xl-start'>
              <div className='open-video-container'>
                <div className='video-cursor'>
                  <OpenVideo
                    link={videos[carouselIndex].link}
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        element_action: 'click video',
                        element_name: videos[carouselIndex].titleVideo,
                        section_name: 'Galeria de vídeos Investindo na prática',
                        redirect_url: videos[carouselIndex].link,
                      })
                    }}
                  >
                    <img
                      src={playVideo}
                      alt='Assistir vídeo'
                      width={`${width < WIDTH_MD ? 35 : 80}`}
                      className='cursor-pointer'
                    />
                  </OpenVideo>
                </div>
              </div>
              {videos[carouselIndex].canShow &&
                <ImageWebp
                  pathSrc={videos[carouselIndex].thumbnail}
                  altDescription='sss'
                  arrayNumbers={[ 312, 696, 616, 840 ]}
                  className='video'
                /> }
            </div>
            { width > WIDTH_MD &&
              <div className='col-12 pl-lg-0'>
                <span className='fs-lg-32 lh-lg-40 text-left text-grayscale--400 fw-400 mt-3 d-lg-block'>Módulo {carouselIndex + 1}</span>
                <h2 className='fs-lg-32 lh-lg-40 text-left mb-md-3 mb-lg-4 text-grayscale--500 fw-600'>{videos[carouselIndex].canShow && videos[carouselIndex].title}</h2>
                {
                  videos[carouselIndex].canShow && videos[carouselIndex].list.map((items: {topics: string}, index: number) => (
                    <div className='d-flex align-items-center mb-3' key={index}>
                      <Number className='fs-12 d-flex align-items-center justify-content-center mr-3'>{index + 1}</Number>
                      <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 mb-0 text-grayscale--400'>{items.topics}</p>
                    </div>
                  ))
                }
              </div>
            }
            {width > WIDTH_MD &&
              <div>
                <h3 className='fs-20 fs-lg-25 lh-25 lh-lg-30 fw-600 text-grayscale--500 mt-md-5'>Conteúdo de apoio:</h3>
                <div className='row'>
                  {supportingContentJSON.map((item: SupportingContentProps) => (
                    <div key={item.title} className='col-md-4 col-lg-3 d-flex justify-content-between align-items-center'>
                      <Content className='border rounded-4 bg-white px-3 py-3'>
                        <p className='fs-md-14 lh-md-17 lh-lg-20 text-grayscale--500 mb-md-3'>{item.title}</p>
                        <a
                          target='blank'
                          href={item.url}
                          onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          element_name: 'Acessar',
                          section_name: 'Olá, Bem-vindo(a) à websérie Finanças na Prática - Conteúdo de apoio',
                          redirect_url: item.url,
                        })
                          }}
                        >
                          <span className='fs-md-14 fs-lg-16 lh-md-17 text-orange--extra fw-600'>Acessar</span>
                        </a>
                      </Content>
                    </div>
              ))}
                </div>
              </div>
          }
          </PlayerVideoContainer>
          {
            width > WIDTH_MD &&
              <div className='col-lg-4 col-xl-3 scroll'>
                {videos.map((item: DotsDivsProps, index: number) => (
                  <ScrollTo
                    to='#webserie'
                    section='dobra_01'
                    sectionName='Olá, Bem-vindo(a) à websérie Finanças na Prática - Conteúdo de apoio'
                    elementName='Acessar'
                    key={index}
                  >
                    <div
                      key={index}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_02',
                          element_action: 'click video',
                          element_name: item.titleVideo,
                          section_name: 'Galeria de vídeos Investindo na prática',
                          redirect_url: item.link,
                        })
                      }}
                    >
                      <ThumbContainer
                        className='mr-3 mr-lg-0'
                        onClick={() => {
                          if (item.canShow) {
                            setCarouselIndex(index)
                          }
                        }
                      }
                      >
                        <div className='d-flex align-items-center justify-content-center'>
                          <img
                            src={playVideo}
                            alt='Assistir vídeo'
                            width={`${width <= WIDTH_MD ? 33 : 41}`}
                            className='cursor-pointer play'
                          />
                          <ImageWebp
                            pathSrc={item.thumbnail}
                            altDescription={item.title}
                            className='thumb'
                          />
                        </div>
                        <div className={videos[index].canShow ? 'd-none' : 'd-none'} />
                        <p className='fs-16 lh-20 text-left text-grayscale--400 mt-3 mb-md-4 d-block'>
                          <span className='d-block'>Módulo {index + 1}</span> <span className='fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</span>
                        </p>
                      </ThumbContainer>
                    </div>
                  </ScrollTo>
                ))}
              </div>
          }
        </div>
        <div className='row justify-content-center mt-3 d-lg-none'>
          <div className='col-12 col-md-8 pl-md-0 px-0'>
            {width > WIDTH_MD &&
              <>
                <span className='fs-24 lh-30 text-left text-grayscale--400 mt-3 text-grayscale--400'>Módulo {carouselIndex + 1}</span>
                <h2 className='fs-24 lh-30 text-left text-grayscale--400 mb-3 text-grayscale--500'>{videos[carouselIndex].canShow && videos[carouselIndex].title}</h2>
              </>
            }
            <div className='d-md-none'>
              { videos[carouselIndex].canShow && videos[carouselIndex].list.map((items: {topics: string}, index: number) => (
                <div className='d-flex align-items-center mb-3' key={index}>
                  <Number className='fs-12 d-flex align-items-center justify-content-center mr-3'>{index + 1}</Number>
                  <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 mb-0 numbers text-grayscale--400'>{items.topics}</p>
                </div>
                ))
              }
            </div>
            <div className='d-none d-md-block d-lg-none'>
              { videos[carouselIndex].canShow && videos[carouselIndex].list.map((items: {topics: string}, index: number) => (
                <div className='d-flex align-items-center mb-3' key={index}>
                  <Number className='fs-12 d-flex align-items-center justify-content-center mr-3'>{index + 1}</Number>
                  <div className='w-100'><p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 mb-0 text-grayscale--400'>{items.topics}</p></div>
                </div>
                ))
              }
            </div>
            {width === WIDTH_MD &&
              <div>
                <h3 className='fs-20 fs-lg-25 lh-25 lh-lg-30 fw-600 text-grayscale--500 mt-md-5'>Conteúdo de apoio:</h3>
                <div className='row'>
                  {supportingContentJSON.map((item: SupportingContentProps) => (
                    <div key={item.title} className='col-md-4 d-flex justify-content-between align-items-center'>
                      <Content className='border rounded-4 px-3 py-3 bg-white'>
                        <p className='fs-md-14 lh-md-17 lh-lg-20 text-grayscale--500 mb-md-3'>{item.title}</p>
                        <a
                          target='blank'
                          href={item.url}
                          onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_01',
                            element_action: 'click button',
                            element_name: 'Acessar',
                            section_name: 'Olá, Bem-vindo(a) à websérie Finanças na Prática - Conteúdo de apoio',
                            redirect_url: item.url,
                          })
                          }}
                        >
                          <span className='fs-md-14 fs-lg-16 lh-md-17 text-orange--extra fw-600'>Acessar</span>
                        </a>
                      </Content>
                    </div>
                ))}
                </div>
              </div>
            }
          </div>
          <div className='col-12 col-md-4 mt-3 d-lg-none scroll'>
            { width >= WIDTH_MD &&
              <div>
                {videos.map((item: DotsDivsProps, index: number) => (
                  <ScrollTo
                    to='#webserie'
                    section='dobra_01'
                    sectionName='Olá, Bem-vindo(a) à websérie Finanças na Prática - Conteúdo de apoio'
                    elementName='Acessar'
                    key={index}
                  >
                    <div
                      key={index}
                      onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_02',
                            element_action: 'click video',
                            element_name: item.titleVideo,
                            section_name: 'Galeria de vídeos Investindo na prática',
                            redirect_url: item.link,
                          })
                      }}
                    >
                      <ThumbContainer
                        onClick={() => {
                          if (item.canShow) {
                            setCarouselIndex(index)
                          }
                        }}
                      >
                        <div className='d-flex align-items-center justify-content-center'>
                          <img
                            src={playVideo}
                            alt='Assistir vídeo'
                            width={`${width <= WIDTH_MD ? 33 : 41}`}
                            className='cursor-pointer play'
                          />
                          <ImageWebp
                            pathSrc={item.thumbnail}
                            altDescription={item.title}
                            arrayNumbers={[ 216, 216, 264, 264 ]}
                          />
                        </div>
                        <div className={videos[index].canShow ? 'd-none' : 'd-none'} />
                        <p className='fs-16 lh-20 text-left text-grayscale--400 mt-3 mb-md-4 d-block'>
                          <span className='d-block'>Módulo {index + 1}</span> <span className='fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</span>
                        </p>
                      </ThumbContainer>
                    </div>
                  </ScrollTo>
                ))
              }
              </div>
            }
            {width < WIDTH_MD &&
              <DotsDiv className='col-12 pt-md-3 pl-md-3 px-0'>
                <DefaultCarousel
                  sm={{ items: 1, partialVisibilityGutter: 80 }}
                  md={{ items: 4 }}
                  lg={{ items: 4 }}
                  xl={{ items: 4 }}
                  removeArrowOnDeviceType={[ 'mobile', 'tablet', 'desktop', 'desktopxl' ]}
                  notShowDots={true}
                  notShowArrows={true}
                  customColor='white'
                  itemClass='boder-bottom'
                >
                  {
                  videos.map((item: DotsDivsProps, index: number) => (
                    <div
                      key={index}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_02',
                          element_action: 'click video',
                          element_name: item.titleVideo,
                          section_name: 'Galeria de vídeos Investindo na prática',
                          redirect_url: item.link,
                        })
                      }}
                    >
                      <ThumbContainer
                        className='mr-3'
                        onClick={() => {
                          if (item.canShow) {
                            setCarouselIndex(index)
                          }
                        }
                      }
                      >
                        <ScrollTo
                          to='#webserie'
                          section='dobra_01'
                          sectionName='Olá, Bem-vindo(a) à websérie Finanças na Prática - Conteúdo de apoio'
                          elementName='Acessar'
                        >
                          <div className='d-flex align-items-center justify-content-center'>
                            <img
                              src={playVideo}
                              alt='Assistir vídeo'
                              width={`${width <= WIDTH_MD ? 33 : 41}`}
                              className='cursor-pointer play'
                            />
                            <ImageWebp
                              pathSrc={item.thumbnail}
                              altDescription={item.title}
                              arrayNumbers={[ 216, 216, 264, 264 ]}
                              className='thumb'
                            />
                          </div>
                        </ScrollTo>
                        <div className={videos[index].canShow ? 'd-none' : 'd-none'} />
                        <p className='fs-16 lh-20 text-left text-grayscale--400 mt-3 mb-md-4 d-block'>
                          <span className='d-block'>Módulo {index + 1}</span> <span className='fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</span>
                        </p>
                      </ThumbContainer>
                    </div>
                    ))
                  }
                </DefaultCarousel>
              </DotsDiv>}
            {width < WIDTH_MD &&
              <div className='mt-5'>
                <h3 className='fs-20 fs-lg-25 lh-25 lh-lg-30 fw-600 text-grayscale--500 mb-2'>Conteúdo de apoio:</h3>
                {supportingContentJSON.map((item: SupportingContentProps) => (
                  <a
                    target='blank'
                    href={item.url}
                    key={item.title}
                    onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          element_name: item.title,
                          section_name: 'Olá, Bem-vindo(a) à websérie Finanças na Prática - Conteúdo de apoio',
                          redirect_url: item.url,
                        })
                    }}
                  >
                    <div key={item.title} className='d-flex justify-content-between align-items-center py-4 border-bottom'>
                      <p className='fs-14 fs-md-16 lh-17 lh-lg-20 text-grayscale--500 mb-0'>{item.title}</p>
                      <OrangeIcon size='MD' color='#FF7A00' icon='chevron-right' />
                    </div>
                  </a>
                  ))}
              </div>
              }
          </div>
        </div>
      </div>
    </VideoSection>
  )
}

export default NossosVideos
