import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import { Hero, ConstruaSuaJornada } from './sections/_index'

const InvestindoNaPratica = () => {
  return (
    <>
      <Layout pageContext={pageContext}>
        <Hero />
        <ConstruaSuaJornada />
      </Layout>
    </>
  )
}

export default InvestindoNaPratica
