import styled from 'styled-components'

export const Section = styled.section`

  h1 {
    font-family: 'Citrina';
  }
  h2 {
    font-family: 'Citrina'
  }
`
