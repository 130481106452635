import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const VideoSection = styled.section`

  h1, h2, h3, span {
    font-family: 'Citrina';
  }

  .numbers {
    width: 100%;
  }

  .logo-inter {
    width: 87px;

    @media ${device.desktopLG} {
      width: 116px;
    }
  }

  .scroll {
    @media ${device.tablet} {
      overflow-y: scroll;
      height: 640px;
      scrollbar-width: thin;
      scrollbar-color: #FF7A00 #F5F6FA;
    }

    @media ${device.desktopLG} {
      height: 1000px;
    }

    @media ${device.desktopXL} {
      height: 950px;
    }

    @media ${device.desktopXXXL} {
      height: 1050px;
    }
  }

  background-repeat: repeat;
  background-position: top center;
  background-size: 360px;
  min-height: 460px;

  @media ${device.tablet} {
    min-height: 440px;
    padding-bottom: 30px;
  }
  @media ${device.desktopLG} {
    min-height: 586px;
  }
  @media ${device.desktopXL} {
    padding-top: 103px;
    padding-bottom: 65px;
    min-height: 824px;
  }
  @media ${device.desktopXXXL} {
    min-height: 1098px;
  }

  .video-container {
    position: relative;
    margin: 0 auto;
  }

  .open-video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: +1;
  }
`

export const DotsDiv = styled.div`
  .react-multi-carousel-track {
    padding: 0;
  }
`

export const ThumbContainer = styled.div`
  cursor: pointer;

  .default-background {
    opacity: 0.4;
    cursor: no-drop;
  }

  .thumb {
    width: 100%;
  }

  .play {
    position: absolute;
  }

  p {
    font-family: 'Sora';
  }
`

export const PlayerVideoContainer = styled.div`
  height: auto;

  .video {
    width: 100%;
  }
`

export const Number = styled.div`
  width: 24px;
  height: 22px;
  color: ${white};
  background-color: ${grayscale[500]};
  border-radius: 12px;

  @media ${device.desktopLG} {
    height: 24px;
  }
`

export const Content = styled.div`
  min-height: 130px;

  @media ${device.desktopLG} {
    min-height: 138px;
  }
`
