import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import AcceptTerms from 'src/components/AcceptTerms/FinancasNaPraticaEbook'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import 'react-phone-input-2/lib/style.css'

import errorImg from '../../../../assets/images/form-error-image.png'
import successImg from '../../../../assets/images/form-success-image.png'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { getParameterByName } from 'src/shared/helpers'

import { Container, Close } from './style'

interface IFormValues {
  nome: string;
  email: string;
  cpfCnpj: string;
  aceite: boolean;
}

function FormFinancasNaPratica () {
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()

  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)

  function handleButtonClick () {
    sendDatalayerEvent({
      section: 'dobra_01',
      section_name: 'Construa sua jornada na educação financeira',
      element_action: 'click button',
      element_name: 'Baixe grátis',
    })
  }

  function downdloadEbook () {
    window.open('https://marketing.bancointer.com.br/comunicacao/pdf/cartilha_educacao_financeira_digital.pdf', '_blank')
  }

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      cpfCnpj: data.cpfCnpj.replace(/\D/g, ''),
      campanha: 'Financas na Pratica',
      conteudo01: utmSource || '',
      conteudo02: utmMedium || '',
      conteudo03: utmCampaign || '',
      descricao: 'webserie1',
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setLoading(false)
      setSent(true)
      downdloadEbook()
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Construa sua jornada na educação financeira',
        element_action: 'click button',
        element_name: 'Baixe grátis',
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Construa sua jornada na educação financeira',
        element_action: 'click button',
        element_name: 'Baixe grátis',
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  return (
    <>
      { error && (
        <Container className='d-flex align-items-center'>
          <div className='col-12 text-center py-5'>
            <Close
              title='Fechar'
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                  handleReturn(e)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Construa sua jornada na educação financeira',
                    element_action: 'click button',
                    element_name: 'Fechar',
                  })
              }}
            >
              <OrangeIcon size='MD' color='#FF7A00' icon='exit' />
            </Close>
            <img src={errorImg} alt='Imagem de erro' />
            <h3 className='fs-20 lh-24 mt-4 text-grayscale--500'>Houve um erro por aqui</h3>
            <p className='text-grayscale--500 text-center fs-14 lh-22 mt-3 mb-0'>
              Não foi possível criar o seu cadastro. Experimente voltar dentro de alguns instantes.
            </p>
            <button
              title='Clique aqui para voltar'
              className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-3 mt-md-4'
              onClick={(e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
                  handleReturn(e)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Construa sua jornada na educação financeira',
                    element_action: 'click button',
                    element_name: 'Clique aqui',
                  })
              }}
            >
              Entendi
            </button>
          </div>
        </Container>
      )}
      { sent && (
        <Container className='d-flex align-items-center justify-content-center'>
          <div className='col-12 text-center py-5'>
            <Close
              title='Fechar'
              onClick={(e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
                handleReturn(e)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Construa sua jornada na educação financeira',
                  element_action: 'click button',
                  element_name: 'Fechar',
                })
              }}
            >
              <OrangeIcon size='MD' color='#FF7A00' icon='exit' />
            </Close>
            <img src={successImg} alt='Imagem de erro' />
            <h3 className='fs-24 lh-30 text-grayscale--500 fw-600 mt-3'>
              <span className='d-block'>Cadastro realizado</span> com sucesso!
            </h3>
          </div>
        </Container>
      )}
      <Container className={`${error || sent ? 'd-none' : 'd-flex'} align-items-center`}>
        <div className='w-100'>

          <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
            <div className='body-form'>
              <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                <label htmlFor='nome' className='fs-14 lh-16 fw-600 text-grayscale--500'>Nome</label>
                <input
                  ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                  name='nome'
                  id='nome'
                  type='text'
                  maxLength={100}
                  placeholder='Digite seu nome completo'
                />
                {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
              </div>
              <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3'>
                <label htmlFor='email' className='fs-14 lh-16 fw-600'>E-mail</label>
                <input
                  ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                  name='email'
                  id='email'
                  type='text'
                  placeholder='Digite seu e-mail'
                />
                {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
              </div>
              <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3'>
                <label htmlFor='cpfCnpj' className='fs-14 lh-16 fw-600'>CPF</label>
                <input
                  ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                  name='cpfCnpj'
                  id='cpfCnpj'
                  type='text'
                  placeholder='Digite seu CPF'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpfCnpj', Masks.MaskCPF(event.currentTarget.value))}
                />
                {errors.cpfCnpj && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpfCnpj.message}</p>}
              </div>
              <div className='col-12 d-flex flex-column px-0 px-md-3'>
                <AcceptTerms
                  accept={accept} setAccept={setAccept}
                  name='promocao-tardezinha-miami'
                />
              </div>
              <div className='col-12 text-center px-md-3'>
                <button
                  type='submit' title='Enviar contato' disabled={!accept || loading}
                  className='btn btn--lg btn-orange--extra fw-600 rounded-2 mx-auto text-white text-none'
                  onClick={() => {
                      handleButtonClick()
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Construa sua jornada na educação financeira',
                        element_action: 'click button',
                        element_name: 'Baixe grátis',
                      })
                  }}
                >
                  {loading ? 'Enviando...' : 'Baixe grátis'}
                </button>
              </div>
            </div>
          </form>

        </div>
      </Container>
    </>
  )
}

export default FormFinancasNaPratica
