import React from 'react'
import FinancasNaPraticaEbook from 'src/components/UI/Forms/FinancasNaPraticaEbook'
import { Section } from './style'
const Tag = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pratica-tag-bonus/image.webp'

const ConstruaSuaJornada = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 flex-column'>
            <div className=''>
              <img
                src={Tag}
                width='250px'
                alt='Construa sua jornada na educação financeira'
              />
            </div>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 fw-600 text-grayscale--500 mt-3'>
              Construa sua jornada na educação financeira
            </h2>
            <p>
              Com este cronograma, você irá assumir o controle da sua vida financeira.
            </p>
          </div>
          <div className='col-12 col-md-6 offset-lg-1 py-4 px-4 px-md-3 mt-3 rounded-4 shadow border'>
            <FinancasNaPraticaEbook />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ConstruaSuaJornada
