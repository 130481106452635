import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`

  .form--default {

    input {
      background: ${grayscale[100]};
      border-radius: 8px;
      margin-bottom: 4px;
      height: 48px;
      font-weight: 800;
    }

    label {
      color: ${grayscale[500]};
      margin-bottom: 4px;
      font-weight: 400;
    }

    button {
      max-width: 100%;
    }
  }
`

export const Close = styled.div`
  float: right;
  top: -10px;
  right: -10px;
  position: absolute;
  cursor: pointer;

  @media ${device.tablet} {
    right: 0;
  }
`
